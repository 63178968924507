import { BMWColors, StandardColors } from 'library/styles';

export const MapNavigationLayerColors = [
  StandardColors.CardIndicator1,
  BMWColors.Accent1,
  StandardColors.CardIndicator2,
  StandardColors.CardIndicator3,
];

export const MapTransparency = {
  Opaque: 1,
  SemiTransparent: 0.2,
  Transparent: 0,
};
