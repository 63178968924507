import { Graphics, Sprite, Texture } from 'pixi.js';
import { VehicleGraphicHelper } from '../helpers/vehicle-graphic.helper';
import { ForkliftDimensions, isForkliftDimensions } from '../vehicle-dimensions.model';
import { VehicleTextures } from '../vehicle-layer-texture.constant';
import { ForkliftMapItem } from '../vehicle-types/forklift.graphic';

/*
  Total Length incl Forks: 1511mm
  Body Length: 3010mm
  Body Width: 1300mm
 
  Pivot: 1364mm from Fork rear
*/

const dimensions: ForkliftDimensions = {
  length: 301.0,
  width: 130,
  origin: 136.4,
  fork: {
    width: 50,
    length: 115,
    loadOffset: 10,
  },
  load: {
    width: 100,
    length: 200,
  },
};

export class ForkliftDsAmadeusMapItem extends ForkliftMapItem {
  protected getDimensions(): ForkliftDimensions {
    let dim: ForkliftDimensions = this.getVehicleForkDimensions({ ...dimensions });
    const factsheetDimension = this.getFactsheetDimensions(dim);

    if (isForkliftDimensions(factsheetDimension)) dim = factsheetDimension;

    return dim;
  }

  protected getArrowPosition(): number | undefined {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return -40;
  }

  protected getBodyTexture(): Texture {
    return VehicleTextures.forkliftDsAmadeus;
  }

  protected createVehicleBase(): Graphics | Sprite {
    return VehicleGraphicHelper.createVehicleBaseFromTexture(
      this.getBodyTexture(),
      this.dimensions
    );
  }

  protected createForks(): Graphics | Sprite {
    return VehicleGraphicHelper.createForksFromTexture(
      VehicleTextures.forkliftDsAmadeusForks,
      this.dimensions
    );
  }
}
