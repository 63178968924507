import { DensityColors } from './colors.enum';

export const DevColors = {
  Green: 0x00bd29,
  Red: 0xff0000,
};

export const MapColors = {
  Base: 0xffffff, // Base is used for Graphics with Tint
  Background: 0xffffff,
  BorderSelected: 0xfecb00,
};

export const MapLabelColors = {
  Background: 0xffffff,
  WhiteText: 0xffffff,
};

export const VehicleColors = {
  Base: MapColors.Base,
  Border: 0x444444,
  Default: 0xfecb00,
  GenericType: 0xa2ad00,
  SwitchedOff: 0xbdbdbd,
  Maintenance: 0xa43ed1,
  Error: 0xc32e2d,
  ArrowGrey: 0x444444,
  Loaded: 0x3f7bfe,

  TagGrey: 0x353a41,
  TagDS: 0xcb263e,

  ForkliftBody: 0xfea366,
  ForkColor: 0x000000,
  ForkShadowColor: 0x8f8f8f,

  DrawBar: 0x333333,

  Trailer: 0xa9e8ec,
};

export const VehiclePathColors = {
  BaseColor: MapColors.Base,
  Default: 0x00d9d8,
  Stopped: 0xed8283,
  Selected: 0xffd700,
  PathSegments: 0x800080,
  CompletePath: DensityColors.Red400,
};

export const CollisionPointColors = {
  Point: 0xf05960,
  Released: 0xa9a9a9,
  Intersection: 0xff9300,
};

export const ConflictAreaColors = {
  Awareness: 0xf05960,
  DeadLock: 0x8e85ff,
  IntersectionZone: 0x693d86,
  ConflictArea: 0x95a4bd,
};

export const PoiColors = {
  EntryVector: 0x000000,
  ExitVector: 0xd20000,
  Base: MapColors.Base,
  BorderSelected: 0xfecb00,
  Rotation: 0x98a4bb,
  SensorOccupied: 0x008ff0,
  SensorFree: 0xc1c5cb,
};

export const PillarGridColors = {
  Lines: 0xf884ae,
  Icon: 0xd8d8d8,
};

export const NodeColors = {
  Arrow: 0x6c7c97,
  Footer: 0x747d8f,
};
