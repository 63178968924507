/* eslint-disable @typescript-eslint/no-magic-numbers */
import { DropShadowFilter } from '@pixi/filter-drop-shadow';
import { Coordinate } from 'core/dtos';
import { NURB_INTERVAL, Nurb, Pose2D, Vector2D } from 'core/models';
import { Container, Graphics, Sprite, Texture } from 'pixi.js';

import { CM_PER_METER } from 'core/constants';
import {
  GeometryConstant,
  GeometryHelper,
  calculateDistance,
  calculateMidpoint,
  calculateOrientationFromPoints,
  calculatePointOnLine,
  formatDegreesToRadians,
} from 'shared/helpers';
import { PathStyle } from '../layers/map-layer.constant';
import { GraphicsEx } from '../layers/pixi';
import {
  ArrowOptions,
  GraphicStyle,
  IconOptions,
  LineArrowOptions,
  LineOptions,
  SelectedOptions,
} from './map-pixi-helper.model';

// NOTE: HELPERS FOR CREATING GRAPHICS IN PIXI ONLY
export class MapPixiHelper {
  // #region Textures
  static createTexture(options: IconOptions): Texture {
    if (!options.path) throw new Error('A path must be defined to create the Texture');

    return Texture.from(options.path, options);
  }

  static createIcon(texture: Texture, options: IconOptions): Sprite {
    const sprite = new Sprite(texture);
    sprite.pivot.set(sprite.width / 2, sprite.height / 2);
    sprite.scale.set(1 / options.scale); // TODO: This scale is inverted

    return sprite;
  }

  static createIconCenter(texture: Texture): Sprite {
    const sprite = Sprite.from(texture);
    sprite.anchor.set(0.5, 0.5);

    return sprite;
  }
  // #endregion

  // #region Arrows
  static createArrow(options: ArrowOptions): Graphics {
    const graphic = new GraphicsEx();

    if (graphic.drawStar) {
      graphic.lineStyle(0);
      graphic.beginFill(options.color, options.alpha);
      graphic.drawStar(0, 0, PathStyle.ArrowPoints, options.size, 0, options.orientation);

      graphic.position.set(options.x, options.y);
      graphic.endFill();
    }

    return graphic;
  }

  static createArrowOnPosition(
    prevPose: Coordinate,
    position: Coordinate,
    style: GraphicStyle
  ): Graphics {
    const orientation = GeometryHelper.calculateOrientationFromPoints(prevPose, position);

    return this.createArrow({
      x: position.x,
      y: position.y,
      orientation: orientation - GeometryConstant.Degree270InRadians,
      size: PathStyle.ArrowSize,
      ...style,
    });
  }

  static createArrowBetweenPositions(
    prevPose: Pose2D,
    currentPose: Pose2D,
    options: LineArrowOptions
  ): Graphics {
    const baseAngle = 270; //rotation adjusted to point to right direction

    let position: Vector2D;
    if (options.distance) {
      const lineDistance = calculateDistance(prevPose, currentPose);
      const pointPose = calculatePointOnLine(prevPose, currentPose, options.distance);
      const distanceToPoint = calculateDistance(prevPose, pointPose);

      position =
        lineDistance < distanceToPoint ? calculateMidpoint(prevPose, currentPose) : pointPose;
    } else {
      position = calculateMidpoint(prevPose, currentPose);
    }

    const orientation = calculateOrientationFromPoints(prevPose, currentPose);

    return this.createArrow({
      x: position.x,
      y: position.y,
      orientation: orientation - formatDegreesToRadians(baseAngle),
      size: PathStyle.ArrowSize,
      ...options,
    });
  }

  static createArrowOnNurb(options: LineArrowOptions, nurb: Nurb): Graphics | undefined {
    if (nurb.isValid) {
      let arrowStart: Coordinate | undefined;
      let arrowEnd: Coordinate | undefined;
      if (options.distance) {
        const nurbLength = nurb.getTotalNurbLength();

        if (options.distance >= nurbLength) {
          arrowEnd = nurb.calculatePointOnNurb(NURB_INTERVAL);
          arrowStart = nurb.calculatePointOnNurb(0);
          options.size = options.smallSize;
        } else if (nurbLength <= options.distance * 2.4) {
          // Just more than double to prevent star
          const halfDistance = options.distance / 2;
          arrowEnd = nurb.calculatePointOnNurb(halfDistance);
          arrowStart = nurb.calculatePointOnNurb(halfDistance - NURB_INTERVAL);
          options.size = options.smallSize;
        } else {
          arrowEnd = nurb.calculatePointOnNurb(options.distance);
          arrowStart = nurb.calculatePointOnNurb(options.distance - NURB_INTERVAL);
        }
      } else {
        const middle = nurb.getNurbMiddlePoints();
        arrowStart = middle[0];
        arrowEnd = middle[1];
      }

      if (arrowStart && arrowEnd) {
        return MapPixiHelper.createArrowOnPosition(arrowStart, arrowEnd, options);
      }
    } else {
      return MapPixiHelper.createArrowBetweenPositions(
        nurb.startPosition,
        nurb.endPosition,
        options
      );
    }
    return undefined;
  }
  // #endregion

  // #region Lines
  static createLineWithDirectionArrow(
    lineOptions: LineOptions,
    arrowOptions: LineArrowOptions,
    startPosition: Pose2D,
    endPosition: Pose2D
  ): Graphics {
    const line = new GraphicsEx()
      .lineStyle(lineOptions.size, lineOptions.color, lineOptions.alpha)
      .moveTo(startPosition.x, startPosition.y)
      .lineTo(endPosition.x, endPosition.y);

    line.addChild(
      MapPixiHelper.createArrowBetweenPositions(startPosition, endPosition, arrowOptions)
    );

    return line;
  }

  static createNurbLineWithDirectionArrow(
    lineOptions: LineOptions,
    arrowOptions: LineArrowOptions,
    startPosition: Pose2D,
    endPosition: Pose2D,
    nurb: Nurb
  ): GraphicsEx {
    const line = new GraphicsEx()
      .lineStyle(lineOptions.size, lineOptions.color, lineOptions.alpha)
      .moveTo(startPosition.x, startPosition.y);

    nurb.path.forEach(p => line.lineTo(p.x, p.y));

    const arrow = MapPixiHelper.createArrowOnNurb(arrowOptions, nurb);
    if (arrow) line.addChild(arrow);

    line.lineTo(endPosition.x, endPosition.y);

    return line;
  }

  static createPathWithDirectionArrow(
    lineOptions: LineOptions,
    arrowOptions: LineArrowOptions,
    startPosition: Coordinate,
    path: Coordinate[]
  ): Graphics {
    return MapPixiHelper.drawPathWithDirectionArrow(
      new GraphicsEx(),
      lineOptions,
      arrowOptions,
      startPosition,
      path
    );
  }

  static drawPathWithDirectionArrow(
    line: GraphicsEx,
    lineOptions: LineOptions,
    arrowOptions: LineArrowOptions,
    startPosition: Coordinate,
    path: Coordinate[]
  ): GraphicsEx {
    line
      .clear()
      .lineStyle(lineOptions.size, lineOptions.color, lineOptions.alpha)
      .moveTo(startPosition.x, startPosition.y);

    path.forEach(p => line.lineTo(p.x, p.y));

    const last = path.length - 1;
    const arrowPosition = path[last];

    const orientation = GeometryHelper.calculateOrientationFromPoints(path[last - 1], path[last]);

    if (line.drawStar) {
      line.lineStyle(0);
      line.beginFill(arrowOptions.color);
      line.drawStar(
        arrowPosition.x,
        arrowPosition.y,
        PathStyle.ArrowPoints,
        arrowOptions.size ?? PathStyle.ArrowSize,
        0,
        orientation - GeometryConstant.Degree270InRadians
      );
      line.endFill();
    }

    return line;
  }

  // #endregion

  // #region Selection
  static createShadowSelection({ width, size, color }: SelectedOptions): Graphics {
    const graphic = new Graphics().lineStyle(width, color, 0.5, 1).drawCircle(0, 0, size);

    graphic.name = 'selection';
    graphic.filters = [new DropShadowFilter()];
    graphic.visible = true;

    return graphic;
  }

  static createSelection({ width, size, color }: SelectedOptions): Graphics {
    const graphic = new Graphics().lineStyle(width, color).drawCircle(0, 0, size);

    graphic.name = 'selection';
    graphic.visible = true;

    return graphic;
  }

  static removeSelection(container: Container): void {
    const selection = container.children.find(c => c.name === 'selection');

    if (selection) {
      container.removeChild(selection);
      container.filters = null;
      selection.destroy();
    }
  }
  // #endregion

  // #region Shape
  static createShapeFromPoints(lineOptions: LineOptions, path: Coordinate[] = []): GraphicsEx {
    const line = new GraphicsEx();

    if (path.length > 0) {
      const startPosition = path[0];

      line
        .lineStyle(lineOptions.size, lineOptions.color, lineOptions.alpha)
        .moveTo(startPosition.x * CM_PER_METER, startPosition.y * CM_PER_METER);

      path.slice(1).forEach(p => line.lineTo(p.x * CM_PER_METER, p.y * CM_PER_METER));
    }

    return line;
  }
  // #endregion
}
