import { MissionTraceTrigger, MissionTraceTriggerDisplayFilter } from 'core/models';

export const MISSION_TRACE_TRIGGER_MAPPER = {
  [MissionTraceTriggerDisplayFilter.Production]: [MissionTraceTrigger.JobInstruction],
  [MissionTraceTriggerDisplayFilter.Manual]: [
    MissionTraceTrigger.AdhocInstructionFromMission,
    MissionTraceTrigger.AdhocInstructionFromStep,
    MissionTraceTrigger.AdhocInstructionFromProcessChain,
  ],
  [MissionTraceTriggerDisplayFilter.ParkingCharging]: [
    MissionTraceTrigger.SystemChargingInstruction,
    MissionTraceTrigger.SystemParkingInstruction,
    MissionTraceTrigger.SystemBreakTestInstruction,
  ],
  [MissionTraceTriggerDisplayFilter.WaitingQueue]: [
    MissionTraceTrigger.SystemWaitingQueueInstruction,
  ],
  [MissionTraceTriggerDisplayFilter.BeginShift]: [MissionTraceTrigger.BeginShift],
  [MissionTraceTriggerDisplayFilter.LongRunning]: [
    MissionTraceTrigger.LongRunning,
    MissionTraceTrigger.AdHocFromTourConfig,
  ],
  [MissionTraceTriggerDisplayFilter.AssignmentProduction]: [
    MissionTraceTrigger.AssignmentProduction,
  ],
};
