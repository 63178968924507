import { createSelector, MemoizedSelector } from '@ngrx/store';
import { BasicVehicleDimensions, Factsheet, LoadDimensions, Position } from 'core/dtos';
import { GuidString, LoadType, LoadTypeIdentifier, LoadTypeIdentifiers } from 'core/models';

import { CM_PER_METER } from 'core/constants';

import { recordKeyFromValue } from 'core/helpers';
import * as fromFactsheetsReducer from '../reducers/factsheet.reducer';
import * as fromVehicleFeatureState from '../reducers/index';

export const selectFactsheetState = createSelector(
  fromVehicleFeatureState.getVehiclesFeatureState,
  fromVehicleFeatureState.getFactsheetState
);

export const selectFactsheetEntities = createSelector(
  selectFactsheetState,
  fromFactsheetsReducer.getEntities
);

export const selectFactsheetsLoading = createSelector(
  selectFactsheetState,
  fromFactsheetsReducer.getLoading
);

export const selectFactsheetsLoaded = createSelector(
  selectFactsheetState,
  fromFactsheetsReducer.getLoaded
);

export const selectAllFactsheets = createSelector(
  selectFactsheetState,
  fromFactsheetsReducer.getAllFactsheets
);

export const selectFactsheetByVehicleId = (
  vehicleId: GuidString
): MemoizedSelector<fromVehicleFeatureState.VehiclesFeatureState, Factsheet | undefined> =>
  createSelector(selectAllFactsheets, factsheets => {
    return factsheets.find(fs => fs.vehicleId === vehicleId);
  });

// #region Vehicle Dimensions
export interface FactsheetDimensions extends BasicVehicleDimensions {
  geometry: Position[];
  vehicleId: GuidString;
}

export const selectFactsheetDimensions = createSelector(selectAllFactsheets, factsheets => {
  return factsheets.map(fs => calculateDimensions(fs));
});

export const hasFactsheetDimensions = (factsheet: Factsheet): boolean => {
  return (
    factsheet.agvGeometry &&
    factsheet.agvGeometry.envelopes2D &&
    factsheet.agvGeometry.envelopes2D.length > 0
  );
};

export const calculateDimensions = (factsheet: Factsheet): FactsheetDimensions | undefined => {
  if (!hasFactsheetDimensions(factsheet)) return;

  const points = factsheet.agvGeometry.envelopes2D[0].polygonPoints;
  const xAxis = points.map(p => p.x);
  const yAxis = points.map(p => p.y);

  const xMin = Math.min(...xAxis);
  const yMin = Math.min(...yAxis);
  const xMax = Math.max(...xAxis);
  const yMax = Math.max(...yAxis);

  const length = (xMax - xMin) * CM_PER_METER;
  const width = (yMax - yMin) * CM_PER_METER;
  const origin = Math.abs(xMin) * CM_PER_METER;

  return {
    vehicleId: factsheet.vehicleId,
    length,
    width,
    origin,
    geometry: points,
  };
};

// #endregion

// #region Load Dimensions
export const selectAllFactsheetLoadDetails = createSelector(selectAllFactsheets, factsheets => {
  return factsheets
    .map(fs => getLoadDetails(fs))
    .flat()
    .filter(l => isFactsheetLoad(l));
});

export interface FactsheetLoad extends LoadDimensions {
  vehicleId: GuidString;
  loadType: LoadType;
}

export const isFactsheetLoad = (item: FactsheetLoad | undefined): item is FactsheetLoad => {
  return !!item;
};

const getLoadDetails = (factsheet: Factsheet): FactsheetLoad[] | undefined => {
  if (!factsheet.loadSpecification?.loadSets) return;

  return factsheet.loadSpecification.loadSets
    .filter(ls => ls.loadDimensions)
    .map(ls => ({
      vehicleId: factsheet.vehicleId,
      loadType: getLoadType(ls.loadType),
      width: ls.loadDimensions.width * CM_PER_METER,
      length: ls.loadDimensions.length * CM_PER_METER,
      orientation: ls.boundingBoxReference?.theta ?? 0,
    }));
};

const getLoadType = (identifier: LoadTypeIdentifier): LoadType => {
  const key = recordKeyFromValue(LoadTypeIdentifiers, identifier);
  return key ? Number(key) : LoadType.Unknown;
};

// #endregion
