import { createSelector } from '@ngrx/store';
import { EMPTY_GUID } from 'core/constants';
import { MapVehicle, VehicleDto } from 'core/dtos';
import { GuidString, LoadType, VehicleType } from 'core/models';
import { selectAllFactsheetLoadDetails, selectFactsheetDimensions } from './factsheet.selectors';
import { selectAllActiveVehicles } from './vehicles.selectors';

import * as fromMapStore from 'store-modules/maps-store';

const checkNoMap = (vehicle: VehicleDto) => vehicle.vehicleType === VehicleType.Forklift;

const checkWithMap = (vehicle: VehicleDto, mapId: GuidString) =>
  (vehicle.vehicleType === VehicleType.UnitLoad || vehicle.vehicleType === VehicleType.U_AGV) &&
  vehicle?.map?.id === mapId;

const checkWithMapOrEmptyId = (vehicle: VehicleDto, mapId: GuidString) =>
  vehicle.vehicleType === VehicleType.TuggerTrain &&
  (vehicle?.map?.id === mapId || vehicle?.map?.id === EMPTY_GUID);

export const selectActiveVehiclesBySelectedMap = createSelector(
  selectAllActiveVehicles,
  fromMapStore.selectMapId,
  (vehicles, mapId) => {
    return vehicles.filter(
      v => checkNoMap(v) || checkWithMap(v, mapId) || checkWithMapOrEmptyId(v, mapId)
    );
  }
);

export const selectMapVehicles = createSelector(
  selectActiveVehiclesBySelectedMap,
  selectFactsheetDimensions,
  selectAllFactsheetLoadDetails,
  (vehicles, dimensions, loadDetails) => {
    return vehicles.map(v => {
      const dimension = dimensions.find(d => d?.vehicleId === v.id);
      const loads = loadDetails.filter(l => l?.vehicleId === v.id);

      const typedLoad = loads.filter(l => (v.loadType ?? LoadType.Unknown) === l?.loadType);

      const vehicle: MapVehicle = {
        ...v,
        dimensions: dimension,
        load: typedLoad.length > 0 ? typedLoad[0] : loads.length > 0 ? loads[0] : undefined,
      };

      if (dimension && dimension.geometry.length > 0) vehicle.geometry = dimension.geometry;

      return vehicle;
    });
  }
);
