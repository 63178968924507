import { ForkliftDimensions, VehicleDimensions } from '../vehicle-dimensions.model';
import { ForkliftMapItem } from '../vehicle-types/forklift.graphic';

/*
  Total Length incl Forks: 3342mm
  Body Length: 2057mm (0.064 + 1.9930000305175781)
  Body Width: 960mm 
  Fork Length: 1285mm [3342mm-2057mm]
  Fork Width: 800mm 
  Pivot: 64mm (-0.064)
*/

const dimensions: ForkliftDimensions = {
  length: 205.7,
  width: 96,
  origin: 6.4,
  fork: {
    width: 80,
    length: 128.5,
    loadOffset: 10,
  },
  load: {
    width: 100,
    length: 200,
  },
};

export class Forklift4Am15tMapItem extends ForkliftMapItem {
  protected getDimensions(): VehicleDimensions {
    return this.getForkliftFactsheetDimensions(dimensions);
  }
}
