import { ForkliftDimensions, VehicleDimensions } from '../vehicle-dimensions.model';
import { ForkliftMapItem } from '../vehicle-types/forklift.graphic';

/*
  Total Length incl Forks: 3725mm
  Body Length: 2141mm [1.779 + 0.361]
  Body Width: 1320mm 
  Fork Length: 1584mm [3725mm-2141mm]
  Fork Width: 472mm 
  Pivot: 361mm (0.361000001)
*/

const dimensions: ForkliftDimensions = {
  length: 214.1,
  width: 132,
  origin: 36.1,
  fork: {
    width: 47.2,
    length: 1.584,
    loadOffset: 10,
  },
  load: {
    width: 100,
    length: 200,
  },
};

export class Forklift4Am20tMapItem extends ForkliftMapItem {
  protected getDimensions(): VehicleDimensions {
    return this.getForkliftFactsheetDimensions(dimensions);
  }
}
