<ds-modal
  [dimensionsConfig]="{ width: '535px' }"
  #vehicleChangeWorkingAreaModal
  [ds-modal-trigger-for]="vehicleChangeWorkingAreaModal"
  [(isModalOpen)]="isModalOpen"
  (modalDismiss)="onCancelButton()">
  <ds-box-header data-cy="dialog-header">{{
    'shared.vehicles.actions.sendSteps' | translate
  }}</ds-box-header>

  <ds-box-content divider="full" data-cy="dialog-content">
    <div class="modalMessageText1">
      <span> {{ sendStepText }}</span>
    </div>

    <div>
      <div [formGroup]="stepForm" class="g-4x">
        <!-- predefined routes dropdown-->
        <div *ngIf="vehicleType === VehicleType.TuggerTrain">
          <ds-form-field>
            <label ds-label for="routeConfigurationId">
              {{ 'shared.vehicles.sendStep.routeConfigName' | translate }}
            </label>
            <ds-select
              ds-form-validation
              [labellingConfig]="labellingConfig"
              [options]="allRouteConfigurations | dsDropdown : 'name' : 'id'"
              formControlName="routeConfigurationId"
              id="routeConfigurationId"
              data-cy="routeConfigurationIdDropDown"></ds-select>
          </ds-form-field>
          <br />
        </div>

        <div class="row mb-3x">
          <ng-container *ngFor="let step of stepsFormArray.controls; let stepIndex = index">
            <ng-container [formGroup]="$any(stepsFormArray.controls[stepIndex])">
              <div class="col-6">
                <div style="display: flex; align-items: end">
                  <label
                    data-cy="sequenceNumber"
                    class="text-label me-2x mb-2x"
                    style="min-width: 12px"
                    >{{ stepIndex + 1 }}</label
                  >

                  <div style="flex-grow: 1">
                    <app-step-type-selection
                      formControlName="stepType"
                      ngDefaultControl
                      [vehicleType]="vehicleType"
                      [hardwareVersion]="hardwareVersion"
                      (ngModelChange)="
                        onStepTypeChanged($event, $any(stepsFormArray.controls[stepIndex]))
                      "></app-step-type-selection>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div style="display: flex">
                  <div style="flex-grow: 1">
                    <ng-container *ngIf="!isWait($any(step)); else inputTimeTemplate">
                      <ng-container *ngIf="vehicleType === VehicleType.UnitLoad; else nodeTemplate">
                        <app-step-attribute-poi
                          [stepType]="step.value.stepType"
                          [disabled]="attributeDisabled($any(step))"
                          [mapId]="this.mapId"
                          [allPois]="this.pois"
                          formControlName="pointOfInterestGroup"
                          ngDefaultControl></app-step-attribute-poi>
                      </ng-container>
                    </ng-container>
                    <ng-template #nodeTemplate>
                      <app-step-attribute-node
                        *ngIf="
                          !attributeDisabled($any(step)) &&
                          (vehicleType === VehicleType.TuggerTrain ||
                            vehicleType === VehicleType.U_AGV)
                        "
                        [isVisible]="step.value.stepType !== 24"
                        [stepType]="step.value.stepType"
                        [allNodes]="nodes"
                        [mapId]="mapId"
                        formControlName="pointOfInterestGroup"
                        ngDefaultControl></app-step-attribute-node>
                    </ng-template>
                    <ng-template #inputTimeTemplate>
                      <app-step-attribute-timespan
                        formControlName="timeSpan"
                        ngDefaultControl></app-step-attribute-timespan>
                    </ng-template>
                  </div>
                  <div style="display: flex; align-items: end">
                    <app-action-menu
                      class="ms-2x"
                      data-cy="stepMenuButton"
                      [menuItems]="stepItems"
                      (menuOpened)="
                        showHideDeleteAction(); setStepIndex(stepIndex)
                      "></app-action-menu>
                  </div>
                </div>
              </div>

              <div
                *ngIf="stepWaypointArray(stepIndex).controls?.length"
                cdkDropList
                (cdkDropListDropped)="dropWaypoint($event, stepIndex)">
                <div
                  class="row mb-2x"
                  *ngFor="
                    let waypoint of stepWaypointArray(stepIndex).controls;
                    let waypointIndex = index
                  "
                  cdkDrag>
                  <ng-container
                    [formGroup]="$any(stepWaypointArray(stepIndex).controls[waypointIndex])">
                    <div cdkDragHandle class="col-6 pe-4x alignLabelEnd" style="cursor: grab">
                      <span class="text-label">{{
                        'shared.vehicles.sendStep.via' | translate
                      }}</span>
                    </div>
                    <div class="col-6">
                      <div style="display: flex">
                        <div style="flex-grow: 1">
                          <ds-form-field>
                            <ds-select
                              appendTo="body"
                              ds-form-validation
                              name="waypoint"
                              formControlName="waypoint"
                              data-cy="waypointDropdown"
                              required
                              [labellingConfig]="labellingConfig"
                              [options]="
                                waypoints | sortDropdown | dsDropdown : 'name' : 'id'
                              "></ds-select>
                          </ds-form-field>
                        </div>
                        <div style="display: flex; align-items: end">
                          <app-action-menu
                            class="ms-2x"
                            data-cy="waypointMenuButton"
                            [menuItems]="waypointItems"
                            (menuOpened)="
                              setStepIndex(stepIndex); setWaypointIndex(waypointIndex)
                            "></app-action-menu>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <button
          class="ms-5x"
          ds-button
          type="button"
          data-cy="addStepButton"
          (click)="addStep()"
          [icon]="'add'"
          [variant]="'ghost'">
          {{ 'shared.vehicles.actions.addStep' | translate }}
        </button>
        <hr class="lineSep" />
      </div>
    </div>
  </ds-box-content>

  <ds-box-footer data-cy="dialog-footer">
    <button ds-button [variant]="'outline'" data-cy="sendStepCancel" (click)="onCancelButton()">
      {{ 'shared.vehicles.sendStep.cancelText' | translate }}
    </button>

    <button
      ds-button
      [disabled]="stepForm.invalid"
      class="ms-2x"
      (click)="onSendSteps()"
      data-cy="sendStep">
      {{ 'shared.vehicles.sendStep.confirmText' | translate }}
    </button>
  </ds-box-footer>
</ds-modal>
